@font-face {
  font-family: 'Graphik-Regular';
  font-style: normal;
  font-weight: 400 500 600 700 900;
  src: url('../../public/fonts/Graphik-Regular.otf');
}

@font-face {
  font-family: 'Graphik-Italic';
  src: url('../../public/fonts/Graphik-RegularItalic.otf');
  font-style: italic;
}

@font-face {
  font-family: 'Graphik-Bold';
  font-weight: 400 500 600 700 900;
  src: url('../../public/fonts/Graphik-Bold.otf')
}

@font-face {
  font-family: 'Graphik-Semibold';
  src: url('../../public/fonts/Graphik-Semibold.otf')
}

@font-face {
  font-family: 'Graphik-Bold-Italic';
  src: url('../../public/fonts/Graphik-BoldItalic.otf')
}

@font-face {
  font-family: 'Graphik-SemiBold-Italic';
  src: url('../../public/fonts/Graphik-SemiboldItalic.otf')
}

html {
  font-size: 16px;
}
/* 
* {
  font-family: 'Graphik-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
} */

html,
body,
#__next {
  width: 100%;
  height: 100%;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  /* font-family: 'Graphik-Regular', "Roboto", "Helvetica", "Arial", "sans-serif"; */
}

.prevent-body-scroll {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

@keyframes useRippleAnimation {
  to {
    transform: scale(15);
    opacity: 0;
  }
}

input[type='range']::-webkit-slider-thumb {
  pointer-events: all;
  min-width: 18px;
  min-height: 18px;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type='range']::-moz-range-thumb {
  pointer-events: all;
  min-width: 18px;
  min-height: 18px;
  cursor: pointer;
  -moz-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
  cursor: pointer;
}

input[type='range']::-moz-range-track {
  cursor: pointer;
}

::-ms-reveal {
  display: none;
}

/* Hide empty rows in tables */
tr:empty {
  display: none;
}
